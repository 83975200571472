import Decimal from '@st/decimal'
import { delay } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { CashBonus } from '../types'
import { useCashBonusesStore } from '../stores/useCashBonusesStore'
import { useGetTitleByLevel } from './useGetTitleByLevel'
import { useBonusesDeps } from '../useDeps'

interface UseCashBonusReturn {
  isRolling: ComputedRef<boolean>
  isCanClaimed: ComputedRef<boolean>
  bonusAmountForClaim: ComputedRef<string>
  rollingMultiplier: ComputedRef<string>
  status: ComputedRef<'done' | 'rolling'>
  bonusCurrencyIcon: ComputedRef<IconName | ''>
  bonusCurrencyCode: ComputedRef<string | ''>
  claimStatus: Ref<string>
  handleBonusClaim: () => Promise<unknown>
  bonusTimer: ComputedRef<{
    title: string
    expiresAt: string
  }>
  textContentByStatus: ComputedRef<{
    buttonText: string
    timerTitle: string
    buttonIcon: IconName | undefined
  }>
  titleByLevel: ComputedRef<string>
  updateCashBonuses: () => Promise<void>
}

export function useCashBonus(bonus: Ref<CashBonus>): UseCashBonusReturn {
  const { getCurrencyIconById, getCurrencyCodeById } = useBonusesDeps()

  const { format: formatCrypto } = useCryptoFormatter()
  const { format: formatNumber } = useNumberFormatter()

  const isRolling = computed(
    () =>
      !!bonus.value.rolling?.currentAmount &&
      new Decimal(bonus.value.rolling.currentAmount).lessThan(
        bonus.value.rolling.fullAmount,
      ),
  )
  const isCanClaimed = computed(
    () =>
      !bonus.value.rolling ||
      new Decimal(bonus.value.rolling.currentAmount).greaterThanOrEqualTo(
        bonus.value.rolling.fullAmount,
      ),
  )
  const bonusAmountForClaim = computed(() =>
    formatCrypto(bonus.value.amount ?? 0),
  )
  const rollingMultiplier = computed(() =>
    formatNumber(bonus.value.rollingSettings?.multiplier ?? 0),
  )
  const status = computed(() => (isCanClaimed.value ? 'done' : 'rolling'))
  const bonusCurrencyIcon = computed(
    () => getCurrencyIconById(bonus.value.currencyId) ?? '',
  )
  const bonusCurrencyCode = computed(
    () => getCurrencyCodeById(bonus.value.currencyId) ?? 'USDT',
  )

  const { t } = useI18n()
  const textContentByStatus = computed(() => {
    if (bonus.value.rolling?.status === 'paused') {
      return {
        buttonText: t('bonuses.resumeRolling'),
        timerTitle: t('bonuses.timerTitle'),
        buttonIcon: undefined,
      }
    }
    if (bonus.value.rolling?.status === 'new') {
      return {
        buttonText: t('bonuses.startRolling'),
        timerTitle: t('bonuses.timerTitle'),
        buttonIcon: undefined,
      }
    }

    return isRolling.value
      ? {
          buttonText: t('bonuses.play'),
          timerTitle: t('bonuses.timerTitle'),
          buttonIcon: 'play-solid' as const,
        }
      : {
          buttonText: t('bonuses.takeBonus'),
          timerTitle: t('bonuses.timerTitle'),
          buttonIcon: undefined,
        }
  })
  const bonusTimer = computed(() => ({
    title: textContentByStatus.value.timerTitle,
    expiresAt: bonus.value.expiredAt,
  }))
  const { getTitle } = useGetTitleByLevel()
  const titleByLevel = computed(() =>
    getTitle(t('bonuses.bonus'), bonus.value.bonusForDepositProgram?.level),
  )

  const { fetchCashBonuses } = useCashBonusesStore()
  const { execute: claim, status: claimStatus } = useStFetch('/bonus/claim', {
    method: 'post',
    body: computed(() => ({
      bonusId: bonus.value.id,
    })),
    immediate: false,
    watch: false,
  })

  const toast = useToast()
  async function updateCashBonuses() {
    await delay(300)
    await fetchCashBonuses()
  }
  async function handleBonusClaim() {
    try {
      await claim()

      if (claimStatus.value !== 'success') {
        toast.open({
          label: t('bonuses.error'),
          type: 'negative',
        })
        return
      }

      await updateCashBonuses()
    } catch (err) {
      console.error(err)
    }
  }

  return {
    isRolling,
    isCanClaimed,
    bonusAmountForClaim,
    rollingMultiplier,
    status,
    bonusCurrencyIcon,
    bonusCurrencyCode,
    claimStatus,
    bonusTimer,
    textContentByStatus,
    titleByLevel,
    handleBonusClaim,
    updateCashBonuses,
  }
}
